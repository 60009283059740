import React, { useState, useEffect } from "react";
import _ from "lodash";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import string from "randomstring";
import random from "random";

export interface lyric {
    track_name: string,
    artist_name: string,
    album_name: string,
    track_share_url: string,
    explicit: boolean,
    lyrics: {
        body: string[],
        lyrics_copyright: string,
        pixel_tracking_url: string,
    },
}

export const Slider = () => {
    const [hasResults, setHasResults] = useState(false);
    const [data, setData] = useState<lyric[] | undefined>();
    const [tries, setTries] = useState(0);
    const [isHovering, setIsHovering] = useState(false);

    useEffect(() => {
        async function parse() {
            await fetch("https://weeklyhits.xyz/quote/top-lyrics-api")
                .then(res => res.json())
                .then((result) => {
                    console.log(result);
                    if (_.isEmpty(data)) {
                        setData(result);
                        setHasResults(!_.isEmpty(result));
                    }
                    // console.log(data, !_.isEmpty(data), tries);
                },
                    (error) => { }
                )
            setTries(tries + 1);
        }
        if (!hasResults && _.isEmpty(data) && tries < 10) {
            setTimeout(parse, 1000);
        }
    }, [data, hasResults, tries])

    const responsive = { desktop: { breakpoint: { max: 9999, min: 1600 }, items: 4 }, smolDesktop: { breakpoint: { max: 1600, min: 1200 }, items: 3 }, smollerDesktop: { breakpoint: { max: 1200, min: 1000 }, items: 2 }, superSmollerDesktop: { breakpoint: { max: 1000, min: 0 }, items: 1 } };

    if (!hasResults) {
        return (
            <Carousel
                infinite={true}
                arrows={false}
                autoPlay={true}
                autoPlaySpeed={3500}
                customTransition="all 1.5s"
                transitionDuration={1500}
                showDots={false}
                responsive={responsive}
                draggable={false}
                swipeable={false}
            >
                {[0, 1, 2, 3, 4].map((fake, i) => {
                    const track = {
                        track_name: string.generate(random.int(5,20)),
                        artist_name: string.generate(random.int(5,30)),
                        album_name: string.generate(random.int(5,10)),
                        lyrics: {
                            body: [
                                string.generate(random.int(5,70)),
                                string.generate(random.int(5,70)),
                                string.generate(random.int(5,70)),
                                string.generate(random.int(5,70)),
                                string.generate(random.int(5,70)),
                                string.generate(random.int(5,70)),
                            ],
                        }
                    }
                    return (
                        <div key={i}>
                            <div className={`flex flex-col rounded-md border border-gray-700 bg-gray-700 mx-2 shadow-md p-3`}>
                                <div className="font-bold text-white rounded text-xl truncate-1-lines  opacity-50"><span className="bg-white rounded-md">{track.track_name}</span> <span className="text-xs font-extralight bg-white rounded-md">({track.album_name})</span></div>
                                <div className="text-white text-base truncate-1-lines  opacity-50"><span className="bg-white rounded-md">{track.artist_name}</span></div>
                                <div className="py-3 my-3 border-t border-b  opacity-50">
                                    <div className="truncate-6-lines">
                                        {track.lyrics.body.map((row, i) => {
                                            return <div key={i} className="text-xs my-1 text-white font-extralight rounded-md"><span className="bg-white rounded-md">{row}</span></div>
                                        })}
                                    </div>
                                </div>
                                <div className="flex items-center justify-center">
                                    <button
                                        className="bg-gray-300 rounded py-3 px-5 bg-opacity-50 transition-all text-gray-300 text-opacity-0 font-semibold text-sm"
                                    >
                                        Complete Lyrics on MusixMatch
                                </button>
                                </div>
                            </div>
                            <div className="mt-2 h-6"></div>
                        </div>
                    )
                })}
            </Carousel>
        )
    }

    return (
        <Carousel
            infinite={true}
            arrows={false}
            autoPlay={!isHovering}
            autoPlaySpeed={3500}
            customTransition="all 1.5s"
            transitionDuration={1500}
            showDots={false}
            responsive={responsive}
            draggable={false}
            swipeable={false}
        >
            {data && Object.entries(data).map((lyric, i) => {
                const track = lyric[1];
                return (
                    <div 
                        key={i}
                        onMouseEnter={() => setIsHovering(true)}
                        onMouseLeave={() => setIsHovering(false)}    
                    >
                        <div className={`flex flex-col rounded-md border border-gray-700 bg-gray-700 mx-2 shadow-md p-3`}>
                            <div className="font-bold text-white text-xl truncate-1-lines select-none pointer-events-none">{track.track_name} <span className="text-xs font-extralight">({track.album_name})</span></div>
                            <div className="text-white text-base truncate-1-lines select-none pointer-events-none">{track.artist_name}</div>
                            <div className="py-3 my-3 border-t border-b select-none pointer-events-none">
                                <div className="truncate-6-lines">
                                    {track.lyrics.body.map((row, i) => {
                                        return <div key={i} className="text-sm text-white font-extralight">{row}</div>
                                    })}
                                    <img src={track.lyrics.pixel_tracking_url} alt={track.track_name} className="hidden" />
                                </div>
                            </div>
                            <div className="flex items-center justify-center">
                                <a
                                    href={track.track_share_url}
                                    rel="noreferrer"
                                    target="_blank"
                                    className="bg-gray-300 rounded py-3 px-5 bg-opacity-50 hover:bg-opacity-25 transition-all text-white font-semibold text-sm"
                                >
                                    Complete Lyrics on MusixMatch
                            </a>
                            </div>
                        </div>
                        <div className="mt-2 text-xxs text-white font-extralight px-3 select-none pointer-events-none h-6">{track.lyrics.lyrics_copyright}</div>
                    </div>
                )
            })}
        </Carousel>
    )
};

export default Slider;