import React, { useRef } from "react";
import Slider from "./components/Slider"
import SearchBar from "./components/SearchBar"
import CopyrightBanner from "./components/CopyrightBanner"
import random from "random"

export const App = () => {
    const divRef = useRef<any>(null);
    const formRef = useRef<any>(null);

    const currentURL = window.location.href;
    const url = new URL(currentURL);
    const urlParams = new URLSearchParams(url.search);
    const u = urlParams.get('uid');
    const e = urlParams.get('id');
    if(u) localStorage.setItem('uid',u);
    if(e) localStorage.setItem('extid',e);
    const userId = localStorage.getItem('uid');
    const extId = localStorage.getItem('extid');

    const backgrounds = [
        'bg-bg1',
        'bg-bg2',
        'bg-bg3',
        'bg-bg4',
    ];

    return (
        <div>
            <div className="absolute top-5 right-5">
                <CopyrightBanner/>
            </div>
            <div ref={divRef} className={`w-screen h-screen flex flex-col items-center justify-center ${backgrounds[random.int(0,3)]}`} onClick={() => { document.querySelector('input')?.focus(); localStorage.setItem('autocomplete', ''); }}>
                <form ref={formRef} className="w-full z-10 -mt-64 px-5" action="https://weeklyhits.xyz/s" method="GET">
                    <div className="relative flex flex-row transition-all bg-white w-full max-w-5xl mx-auto rounded-lg hover:shadow-2xl focus-within:shadow-2xl ring-1 ring-gray-500 focus-within:ring-gray-700">
                        <SearchBar formRef={formRef}/>
                        <input value={userId ? userId : ''} type="hidden" name="uid" readOnly={true} />
                        <input value={extId ? extId : ''} type="hidden" name="id" readOnly={true} />
                        <button className="transition-all rounded-sm hover:bg-gray-100 active:outline-none focus:outline-none p-2" type="submit">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                            </svg>
                        </button>
                    </div>
                </form>
            </div>
            <div className="absolute bottom-2 left-0 right-0">
                <Slider/>
            </div>
        </div>
    )
};
