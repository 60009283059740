import React, { useState, useRef } from "react";
import $ from "jquery";

export interface candles {
    s: string,
    o: number[],
    h: number[],
    l: number[],
    c: number[],
    v: number[],
    t: number[],
}

export interface stock {
    description: string,
    displaySymbol: string,
    symbol: string,
    type: string,
    candles: candles
}

export const SearchBar = (props: any) => {
    const [query, setQuery] = useState("");
    const inputRef = useRef<any>(null);

    const handleSearchChange = async (query: string) => {
        if (query.length > 0) {
            $.ajax({
                url: "https://api.bing.com/osjson.aspx",
                dataType: "jsonp",
                data: {
                    JsonType: "callback",
                    JsonCallback: "suggestCallback",
                    Market: "en-US",
                    query: query
                }
            });
        } else {
            localStorage.setItem('autocomplete', '');
        }
    }

    return (
        <div className="w-full">
            <input className="w-full h-16 bg-transparent px-5 text-2xl focus:outline-none z-10" ref={inputRef} value={query} placeholder="type here to search..." type="text" name="q" onChange={e => {setQuery(e.target.value); handleSearchChange(e.target.value)}} required={true} autoFocus autoComplete="off" />
            {query.length > 0 && localStorage.getItem('autocomplete') &&
                <div className="absolute top-full right-0 left-0 flex flex-col transition-all rounded-b-lg bg-white ring-1 ring-green-500 overflow-hidden z-0 -mt-1">
                {localStorage.getItem('autocomplete')?.split(',').splice(0, 5).map((e: any, i: any) => {
                    return <div className="cursor-pointer py-2 px-5 hover:bg-gray-100" onClick={() => { inputRef.current.value = e; props.formRef.current.submit(); }} key={i}>{e}</div>
                })}
            </div>
        }
        </div>
    )
};

export default SearchBar;